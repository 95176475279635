import React from 'react';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][date.getDay()];
  return {
    full: `${year}年${month}月${day}日（${dayOfWeek}）`,
    monthDay: `${month}月${day}日（${dayOfWeek}）`
  };
};

const HolidayNotice = () => {
  // 各種設定
  // 計算が狂うのでtimezone付きで
  const startDateString = "2024-12-26T00:00:00+09:00";
  const endDateString = "2025-01-05T23:59:59+09:00";
  const reopenDateString = "2025-01-06T23:59:59+09:00";
  const holidayName = "年末年始";

  // 現在の日付がお知らせ表示期間内かチェック
  const now = new Date();
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // 表示期間外の場合は null を返す
  if (now < startDate || now > endDate) {
    return null;
  }

  const start = formatDate(startDate);
  const end = formatDate(endDate);

  // 営業再開日を計算（endDateの翌日）
  const reopenDate = new Date(reopenDateString);
  const reopen = formatDate(reopenDate);

  return (
    <div style={{
      margin: '24px 0',
      borderLeft: '4px solid #ef5350',
      paddingLeft: '16px',
      paddingTop: '12px',
      paddingBottom: '12px',
      backgroundColor: '#ffebee'
    }}>
      <div>
        <p><strong>【{holidayName}休業のお知らせ】</strong></p>
        <p>
          {start.full} 〜 {end.full} は {holidayName}休業とさせていただきます。
          この間にいただいたお問い合わせは、{reopen.monthDay}以降に順次対応させていただきます。
          ご迷惑をおかけして申し訳ございませんが、ご理解のほど、よろしくお願いいたします。
        </p>
      </div>
    </div>
  );
};

export default HolidayNotice;
